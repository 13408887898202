import React from "react";
// import { isServer } from "src/constants";
import { formatDateTime } from "src/utils";

const buildDate = process.env.BUILD_DATE
  ? formatDateTime(process.env.BUILD_DATE)
  : "unknown time";

const ShowBuildDate = () => {
  // if (isServer) return null;
  return <p>Web deployed at: {buildDate}</p>;
};

export default ShowBuildDate;
