import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";
import router from "next/router";
import React, { useCallback, useEffect, useState } from "react";
import useHttpErrorHandler from "src/hooks/useHttpErrorHandler";
import { paths as userProductPath } from "../../userProduct/utils";
import * as Api from "../api";
import { IPartnerUserProductDashboard } from "../types";

const useStyles = makeStyles({
  root: {
    minWidth: 240,
    // maxWidth: 300,
    margin: 5,
    backgroundColor: "#FFFFFF"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    // marginBottom: 12
  }
});

const SimpleDashboardCard = (props: {
  topLabel?: string;
  count?: number;
  bottomLabel?: string;
  onClick?: () => void;
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardActionArea onClick={props.onClick}>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            align="center"
            gutterBottom
          >
            {props.topLabel}
          </Typography>
          <Typography variant="h5" component="h2" align="center">
            {props.count}
          </Typography>
          <Typography
            className={classes.pos}
            color="textSecondary"
            align="center"
          >
            {props.bottomLabel ?? "รายการ"}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const DashboardUserProduct: React.FC = _ => {
  const { handleError } = useHttpErrorHandler();
  const [state, setState] = useState<{
    fetching: boolean;
    data?: IPartnerUserProductDashboard;
  }>({ fetching: false });

  const fetchData = useCallback(async () => {
    setState(prev => ({ ...prev, fetching: true }));
    try {
      const resp = await Api.getUserProductDashboard();
      setState({ fetching: false, data: resp.value });
    } catch (e) {
      handleError(e);
      setState({ fetching: false, data: undefined });
    }
  }, [handleError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <pre>
        <Grid container>
          <Grid item xs={12} md={6} lg>
            <SimpleDashboardCard
              topLabel="User Product ทั้งหมด"
              count={state.data?.countTotal}
              onClick={() => router.push(userProductPath.index)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg>
            <SimpleDashboardCard
              topLabel="User Product ที่ยังไม่ได้ผูกกับสินค้า"
              count={state.data?.countNotMapped}
              onClick={() =>
                router.push(`${userProductPath.index}?mappedProduct=false`)
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg></Grid>
          <Grid item xs={12} md={6} lg></Grid>
          <Grid item lg={2}></Grid>
        </Grid>
        {/* Dashboard:{" "}
              {state.fetching ? "Loading..." : JSON.stringify(state.data, null, 2)} */}
      </pre>
    </>
  );
};

export default DashboardUserProduct;
