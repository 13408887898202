import { fetchJson } from "src/utils/apiUtils";
import { IPartnerUserProductDashboard } from "./types";

export const getUserProductDashboard = async () => {
  return fetchJson<IResponse<IPartnerUserProductDashboard>>(
    `dashboard/userProduct`,
    {
      method: "GET"
    }
  );
};
