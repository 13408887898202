import React from "react";
import ShowBuildDate from "src/apps/commons/misc/ShowBuildDate";
import AuthRender from "src/apps/modules/auth/components/AuthRender";
import DashboardUserProduct from "./DashboardUserProduct";

const Dashboard: React.FC = _ => {
  return (
    <div style={{ paddingLeft: 32 }}>
      <ShowBuildDate />
      <AuthRender roleOfficer>
        <DashboardUserProduct />
      </AuthRender>
    </div>
  );
};

export default Dashboard;
