import React from "react";
import { useSelector } from "react-redux";

const AuthRender: React.FC<{
  roleSetting?: boolean;
  roleOfficer?: boolean;
}> = props => {
  const { roleSetting, roleOfficer, children } = props;
  const { userInfo } = useSelector((state: RootStore) => state.auths);
  const userRoles = userInfo?.roles ?? [];

  const hasRole = userRoles.some(
    r =>
      (roleSetting && r === "ROLE_SETTING") ||
      (roleOfficer && r === "ROLE_OFFICER") ||
      r === "ROLE_ADMIN"
  );

  return <>{hasRole && children}</>;
};

export default AuthRender;
